<template>
  <section>
    <div class="container">
      <SeachableTable
        :fields="fields"
        :items="items"
        :commands="commands"
        @command="onCommand"
        @select="onSelect"
      />
      <div style="margin-top: 20px">
        <div class="btn btn-default" @click.stop.prevent="onNew">
          new instance
        </div>
      </div>
      <!-- <pre style="margin-top: 40px">
        {{ accessToken() }}
      </pre> -->
    </div>
  </section>
</template>

<script>
import Auth from "@/services/auth.js";
import SeachableTable from "@/components/searchable-table.vue";
export default {
  components: {
    SeachableTable
  },
  data() {
    return {
      items: []
    }
  },
  computed: {
    fields() {
      return [
        {
          name: "pid"
        },
        {
          name: "name"
        },
        {
          name: "pid"
        },
        {
          name: "cpu"
        },
        {
          name: "memory"
        },
        {
          name: "pm_uptime"
        },
        {
          name: "status"
        }
      ];
    },
    commands() {
      return [
        {
          name: "toggle",
          title: "toggle",
          icon: (item) => {
            if (item.status == 'online') {
              return 'fa fa-stop text-danger';
            }
            else {
              return 'fa fa-play-circle-o text-success';
            }
          }
        },
        {
          name: "remove",
          title: "remove",
          icon: "fa fa-trash"
        }
      ]
    }
  },
  methods: {
    onCommand(ev) {
      if (ev && ev.name) {
        if (ev.name in this && typeof this[ev.name] == "function") {
          this[ev.name](ev);
        }
      }
    },
    toggle(ev) {
      var item = ev.target;
      var action = item.status == 'online' ? 'stop' : 'start'
      var port = item.name.replace(/\D/g, '');
      this.$http
        .get(
          `http://localhost:9090/api/v1/nred/${port}/${action}`,
          this.auth.requestOptions()
        )
        .then(
          (resp) => {
            if (resp && resp.body) {
              const ix = this.items.findIndex(({ name }) => resp.body.name == name);
              if (ix >= 0) {
                this.$set(this.items, ix, resp.body);
              }
              return;
            }
          },
          (error) => {
          }
        );
    },
    remove(ev) {
      const item = ev.target;
      var port = item.name.replace(/\D/g, '');
      this.$http
        .get(
          `http://localhost:9090/api/v1/nred/${port}/remove`,
          this.auth.requestOptions()
        )
        .then(
          () => {
            this.items = this.items.filter(({ name }) => item.name !== name);
            return;
          },
          (error) => {
          }
        );
    },
    fetchAll() {
      return new Promise((resolve, reject) => {
        this.$http.get("http://localhost:9090/api/v1/nred/list", this.auth.requestOptions()).then(
          (resp) => {
            if (resp && resp.body) {
              resolve(resp.body);
              return;
            }
            reject(null)
          },
          (error) => {
            reject(null)
          }
        );
      })
    },
    onNew() {
      this.fetchAll().then((items) => {
        var port = (_.max((items || []).map(({ name }) => parseInt(name.replace(/\D/g, '')))) || 1879) + 1;
        this.$http
          .get(
            `http://localhost:9090/api/v1/nred/${port}/start`,
            this.auth.requestOptions()
          )
          .then(
            (resp) => {
              if (resp && resp.body) {
                this.items.filter(({ name }) => resp.body.name !== name);
                this.items.push(resp.body);
                return;
              }
            },
            (error) => {
            }
          );
      });
    },
    accessToken() {
      return this.auth.requestOptions()
    },
    onSelect(item) {
      let port = item.name.replace(/\D/g, '')
      let token = this.accessToken().headers.Authorization.replace(/Bearer /, '');
      window.open(`http://${port}.nred.me/?access_token=${token}`, '_blank').focus();
    }
  },
  created() {
    this.auth = new Auth();
    this.fetchAll().then((items) => this.items = items);
  }
};
</script>